import { Component, Input, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Observable } from "rxjs/Observable";

@Component({
  selector: "steps",
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent implements OnInit {
  currentUrl: string;
  currentPath: string = "";
  showSteps = true;

  @Input("stepsInfo") stepsInfo;
  @Input("profile") profile;

  constructor(private router: Router) {
    router.events.subscribe((url: any) => {
      if (url instanceof NavigationEnd) {
        const completeUrl = url.url;
        if (completeUrl.indexOf("?") !== -1) {
          this.currentPath = completeUrl.substring(0, completeUrl.indexOf("?"));
        } else {
          this.currentPath = completeUrl;
        }
        this.setStepsVisibility();
        this.goPath(this.currentPath);
        this.changeText(this.currentPath);
      }
    });
  }

  ngOnInit() {
    let path = window.location.hash.replace("#", "");
    if (path.indexOf("?") !== -1) {
      path = path.substring(0, path.indexOf("?"));
    }
    this.currentPath = this.currentPath || path;

    this.setStepsVisibility();
    this.goPath(this.currentPath);
  }

  getRouterLink(step) {
    return "/" + step.URL;
  }

  getActiveSeparator(index) {
    if (index < this.stepsInfo.length - 1) {
      return this.stepsInfo[index + 1].selected;
    }
    return false;
  }

  setStepsVisibility() {
    // En las ventanas comunes en CI y CJ, no se muestran los steps de este componente.
    this.showSteps =
      this.currentPath != "/metodo-validacion" &&
      this.currentPath != "/identificacion-iban" &&
      this.currentPath != "/verificacion-cuenta" &&
      this.currentPath != "/datos-personal" &&
      this.currentPath != "/bienvenida-segundo-titular" &&
      this.currentPath != "/pendiente-verificacion-transferencia" &&
      this.currentPath != "/confirmacion-otp-primer-paso";
  }

  goPath(path) {
    if (!path || typeof this.stepsInfo == "undefined") return false;
    this.currentPath = path.substring(1);

    for (let i = 0; i < this.stepsInfo.length; i++) {
      let currentStep = this.stepsInfo[i];
      let elementSubUrl = currentStep.subURLs.filter((item) => {
        return item == this.currentPath;
      });

      if (
        this.currentPath == currentStep.URL ||
        (elementSubUrl.length > 0 && elementSubUrl[0] == this.currentPath)
      ) {
        currentStep.selected = true;
        for (let j = 0; j < this.stepsInfo.length; j++) {
          let steptreaty = this.stepsInfo[j];
          if (j <= i) {
            steptreaty.selected = true;
          } else {
            steptreaty.selected = false;
          }
        }
        break;
      }
    }
  }

  changeText(currentPath) {
    if (currentPath === "evo-protect/requisitos") {
      this.stepsInfo[0].text = "Antes de..";
      this.stepsInfo[1].text = "Boletín de Adhesión";
      this.stepsInfo[2].text = "Pago";
      this.stepsInfo[3].text = "Confirmación";
    }
    if (currentPath === "evo-protect/logalty") {
      this.stepsInfo[0].text = "Tarificación previa";
      this.stepsInfo[1].text = "Firma del Boletín de Adhesión";
      this.stepsInfo[2].text = "Pago";
      this.stepsInfo[3].text = "Confirmación";
    }
    if (currentPath === "evo-protect/configura-microseguro") {
      this.stepsInfo[0].text = "Tarificación previa";
      this.stepsInfo[1].text = "Firma del Boletín de Adhesión";
      this.stepsInfo[2].text = "Configuración y Pago EVO Protect";
      this.stepsInfo[3].text = "Confirmación";
    }
    if (currentPath === "evo-protect/todo-listo") {
      this.stepsInfo[0].text = "Tarificación previa";
      this.stepsInfo[1].text = "Firma del Boletín de Adhesión";
      this.stepsInfo[2].text = "Configuración y Pago EVO Protect";
      this.stepsInfo[3].text = "Confirmación";
    }
  }

  changeRoute(step) {
    if (step.selected) {
      //Los pasos selected son por los que ya se ha pasado
      //Comprobamos que no se haya pulsado en un paso posterior, solo se permite ir hacia atrás
      this.stepsInfo.forEach((s) => {
        if (s.selected && s.id > step.id) this.router.navigateByUrl(step.URL);
      });
    }
  }
}