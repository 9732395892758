<!-- <div
  class="user-path"
  *ngIf="showSteps"
  [ngClass]="profile ? 'profile' : ''"
  id="user-path"
>
  <div class="container-main" *ngFor="let step of stepsInfo; let i = index">
    <a user-tooltip [tooltip]="step.tooltip" (click)="changeRoute(step)">
      <h3
        class="step evo"
        [class]="step.icon"
        [ngClass]="{
          active: step.selected,
          'step-select': currentPath === step.URL,
          inactive: !step.selected
        }"
      ></h3>
      <span class="hidden">{{ step.tooltip }}</span>
    </a>
    <h3
      *ngIf="i < stepsInfo.length - 1"
      class="separator"
      [ngClass]="{ active: getActiveSeparator(i) }"
    ></h3>
    <span
      class="text"
      [ngClass]="{
        'active-text': step.selected,
        'step-select': currentPath === step.URL
      }"
      >{{ step.text }}
    </span>
  </div>
</div> -->
<router-outlet></router-outlet>
